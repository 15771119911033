// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.divider {
  display: flex;
  align-items: center;
  text-align: center;
  width: 100%;
  margin: 10px 0;
  font-size: 0.75rem;
  color: #666666;
}
@media (max-width: 640px) {
  .divider {
    font-size: 0.625rem;
  }
}
.divider::before, .divider::after {
  content: " ";
  flex-grow: 1;
  margin: 0 10px;
  border-bottom: 1px solid transparent;
  height: 1px;
  background: repeating-linear-gradient(to right, transparent, transparent 4px, #666 4px, #666 8px);
}
.divider__text {
  white-space: nowrap;
}`, "",{"version":3,"sources":["webpack://./src/components/ui/Divider/divider.scss"],"names":[],"mappings":"AAEA;EACE,aAAA;EACA,mBAAA;EACA,kBAAA;EACA,WAAA;EACA,cAAA;EACA,kBAAA;EACA,cAAA;AADF;AAGE;EATF;IAUI,mBAAA;EAAF;AACF;AAEE;EAEE,YAAA;EACA,YAAA;EACA,cAAA;EACA,oCAAA;EACA,WAAA;EACA,iGAAA;AADJ;AAUE;EACE,mBAAA;AARJ","sourcesContent":["@import '../../../assets/vars.scss';\n\n.divider {\n  display: flex;\n  align-items: center;\n  text-align: center;\n  width: 100%;\n  margin: 10px 0;\n  font-size: 0.75rem;\n  color: #666666;\n\n  @media (max-width: $s) {\n    font-size: 0.625rem;\n  }\n\n  &::before,\n  &::after {\n    content: ' ';\n    flex-grow: 1;\n    margin: 0 10px;\n    border-bottom: 1px solid transparent;\n    height: 1px;\n    background: repeating-linear-gradient(\n      to right,\n      transparent,\n      transparent 4px,\n      #666 4px,\n      #666 8px\n    );\n  }\n\n  &__text {\n    white-space: nowrap;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
