const en = {
  translation: {
    ADMINISTRATION: '',
    FOOTER: {
      SPAN: '',
    },
  },
};

export default en;
