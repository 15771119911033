import { createRoot } from 'react-dom/client';
import { I18nextProvider, initReactI18next } from 'react-i18next';
import i18n from 'i18next';
import { LOCAL_STORAGE } from './constants';
import en from './translation/en';
import './assets/style.scss';
import App from './App';

const language = localStorage.getItem(LOCAL_STORAGE.LANG);

// eslint-disable-next-line
i18n.use(initReactI18next).init({
  lng: language || 'en',
  resources: {
    en,
  },
  fallbackLng: 'en',
});

const rootElement = document.getElementById('root')!;
const root = createRoot(rootElement);

root.render(
  <I18nextProvider i18n={i18n}>
    <App />
  </I18nextProvider>
);
